




































import { defineComponent } from '@vue/composition-api';
import { usePatentSubscribeStore } from './store';
import Descriptions from '@/components/Descriptions.vue';
import { Message, MessageBox } from 'element-ui';
import { cancelSubscribePatent, renewSubscribe } from './api';

export default defineComponent({
  components: { Descriptions },
  setup(props, { root }) {
    const { state } = usePatentSubscribeStore();
    if (state.current === null) {
      root.$router.replace({ name: 'PatentSubscribe' });
    }

    return {
      detail: state.current?.target,
      cancelSubscribe: () => {
        MessageBox.confirm(`是否取消专利 ${state.current?.target.title} 的续费提醒？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          if (state.current?.target.no) {
            try {
              await cancelSubscribePatent(state.current?.target.no);
              Message.success('取消提醒成功');
            } catch {
              //
            }
          }
        });
      },
      renew: () => {
        MessageBox.confirm(`是否记录专利 ${state.current?.target.title} 已续费？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          if (state.current?.target.no) {
            try {
              await renewSubscribe(state.current?.target.no);
              Message.success('记录续费成功');
            } catch {
              //
            }
          }
        });
      },
    };
  },
});
